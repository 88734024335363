"use strict";

import {findIn,find,on,addClass, trigger} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind('.js-message', function (message) {
        let messageClose = findIn('.js-message__close', message);
        const messageCloseHandler = (evt) => {
            document.cookie = "message-closed=true; expires='';";
            addClass('d-none', message);
        };
        on('click', (evt) => messageCloseHandler(evt), messageClose)
    });
}